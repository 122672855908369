import React from 'react';
import { Box, Grid } from '@mui/material';

const IMAGES = [
  {
    href: 'https://animalpolitico.com',
    alt: 'logo',
    src: '/ap-logo.svg',
    sizes: [67, 29],
  },
  {
    href: 'https://animalpolitico.com/verificacion-de-hechos',
    alt: 'logo',
    src: '/sabueso-logo.svg',
    sizes: [75, 23],
  },
  {
    href: 'https://www.animalgourmet.com',
    alt: 'logo',
    src: '/animal-gourmet-logo.svg',
    sizes: [80, 80],
  },
  {
    href: 'https://animalpolitico.com/tendencias',
    alt: 'logo',
    src: '/animal-logo.svg',
    sizes: [67, 27],
  },
  {
    href: 'https://newsweekespanol.com',
    alt: 'logo',
    src: '/newsweeklogo.svg',
    sizes: [67, 27],
  },
];

export const PageAssociatedPlans: React.FC = () => {
  return (
    <Grid container columns={10} columnSpacing={2}>
      {IMAGES.map((image) => (
        <Grid item xs={2} textAlign="center">
          <Box>
            <a href={image.href} target="_blank">
              <img
                src={image.src}
                alt={image.alt}
                width={'100%'}
                height={'65px'}
                style={{ objectFit: 'contain' }}
              />
            </a>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
